import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useMediaQuery } from 'usehooks-ts'

import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button'
import acceptableUsePolicyText from 'src/lib/acceptableUsePolicyText'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

const AcceptableUsePolicyPage = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  return (
    <>
      <Metadata
        title="Acceptable Use Policy"
        description="Acceptable Use Policy"
      />

      <div
        className={
          'relative flex flex-col place-items-center p-4 md:p-8 h-screen bg-slate-50'
        }
      >
        <div className={'h-12 w-60'}>
          <a href="/">
            <TheHubIcon variant="default" />
          </a>
        </div>
        <div
          className={
            'h-3/4 w-full max-w-[800px] pt-12 md:px-12 rounded-lg bg-transparent text-center'
          }
        >
          <h2 className={'text-3xl font-extrabold text-slate-700'}>
            Acceptable Use Policy
          </h2>
          <br />
          <div
            className={
              'overflow-y-scroll h-full border border-1 border-slate-200 p-4 rounded-lg bg-[#FFF]'
            }
          >
            <ReactMarkdown
              className={
                'prose prose-sm prose-headings:text-slate-700 prose-strong:text-slate-600 max-w-none text-slate-500 text-left'
              }
              remarkPlugins={[remarkGfm]}
            >
              {acceptableUsePolicyText}
            </ReactMarkdown>
          </div>
          <Button
            className="mt-6 md:mt-8"
            variant="outlined"
            fullWidth={isMobile ? true : false}
            onClick={() => window.history.back()}
          >
            Go Back
          </Button>
        </div>
      </div>
    </>
  )
}

export default AcceptableUsePolicyPage
