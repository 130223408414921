export const acceptableUsePolicyText = `
## ACCEPTABLE USE POLICY

_This policy was last revised on 7 July 2022._

This Acceptable Use Policy (**Policy**) governs your conduct in using  'The Hub'  (**Platform**).

Stafflink Pty Ltd ABN 52 626 030 742 (**STAFFLINK**) may update this Policy from time to time. Where any changes materially affect your rights under this Policy, STAFFLINK will attempt to provide reasonable notice.  You acknowledge and agree that your continued use of the Platform, or any part thereof, constitutes your acceptance of the Policy as amended. If you do not agree to the terms of the Policy at any time, you must immediately cease to use the Platform.

## Requirements of Use

You acknowledge and agree that your right to access and use the Platform is contingent upon your access to and/or use, at your expense, of appropriate and business grade quality hardware, software, network connections, firewalls, anti-malware, and security measures and any other relevant specifications of which you may be advised or which you may be reasonably expected to be aware .

## Prohibited activity

You must not use the Platform in a way which is excessive, unreasonable, unlawful, or harms or is likely to harm the Platform's integrity or inhibits another user from accessing or using the Platform. What constitutes prohibited activity will be determined by STAFFLINK at its sole discretion. By way of guidance only, prohibited activity may include:

1 &emsp; Making the Platform available to any third party or otherwise available for use on any computer systems or devices not owned or wholly controlled by you.

2 &emsp; Distributing or transmitting any part of the Platform by any means.

3 &emsp; Marketing, distributing, leasing, or selling access to the Platform, or offering to do any of the foregoing.

4 &emsp; Integrating or combining the Platform, in whole or part, into any other software or product;

5 &emsp; Save as expressly permitted by law (and only to that extent):

&emsp; (a) disassembling, adapting, merging, translating, decompiling, reverse engineering, or creating derivative works, based on the Platform, in whole or part; or

&emsp; (b) removing or circumventing any technological protection or access control measures,

or attempting to do any of the foregoing.

6 &emsp; Interfering with the operation of the Platform or use of the Platform by any other person.

7 &emsp; Disproportionately consuming the Platform's resources.

8 &emsp; Attempting to use the Platform or access information on the Platform other than as intended and through the ordinary function of the Platform.

9 &emsp; Attempting to circumvent any security or other limitations implemented on the Platform.

10 &emsp; Uploading trojans, viruses, or other malware to the Platform.

11 &emsp; Using or downloading content from the Platform by way of automated software.

12 &emsp; Uploading to, or distributing through, the Platform, any material which constitutes, encourages, enables or facilitates (as determined by STAFFLINK at its sole discretion):

&emsp; (a) hate speech;

&emsp; (b) terrorism;

&emsp; (c) discrimination on the basis of gender, disability, religion, sexuality or ethnicity;

&emsp; (d) defamation, harassment, or physical harm to any person;

&emsp; (e) content or activities of a sexual or explicit nature; or

&emsp; (f) any material or act which is illegal or morally questionable.

13 &emsp; Uploading to, or distributing through, the Platform, any material or information which is incorrect, inaccurate, out-of-date, misleading, deceptive, or likely to mislead or deceive.

14 &emsp; Using the Platform in any may which is fraudulent, malicious, contrary to law or public policy, or that could be detrimental to STAFFLINK's, the Platform's, or any other user of the Platform's, name, goodwill, or reputation.

**Commercial or agency use**

15 &emsp; To the extent that you may be permitted by STAFFLINK to use the Platform on a commercial basis, or on behalf of another person, you must not:

&emsp; (a) use the Platform in any way which is directed to the purpose of competing with or displacing the Platform;

&emsp; (b) use the Platform for the purpose of obtaining subscription revenue by way of advertising the Platform in a manner that may reasonably deceive or mislead any person (including any person to whom you provide or may provide products or services) as to ownership or operation of the Platform;

&emsp; (c) offer to provide access to the Platform to existing users of the Platform or otherwise solicit business from existing users of the Platform (but excluding an independent approach made by an existing user in response to an advertisement or offer made to the general public); or

&emsp; (d) engage in any other conduct that may contravene relevant laws and regulations including but not limited to the  _Competition and Consumer Act 2010_  (Cth).

## Consequences of prohibited activity

If you engage in, or STAFFLINK suspect that you have engaged in, any prohibited activity, STAFFLINK may, at its sole discretion:

1 &emsp; notify you of the activity and require that you cease the prohibited activity within a period specified by STAFFLINK;

2 &emsp; modify or delete the offending material or information;

3 &emsp; suspend your access to the Platform, in whole or part, without notice; or

4 &emsp; terminate your access to the Platform without notice.

You acknowledge and agree that you are not entitled to any remedy as a result of STAFFLINK taking any action under this Policy.
`

export default acceptableUsePolicyText
